<template>
  <div>
    <v-card
      :color="card_background"
      :elevation="elevation"
      v-bind:class="
        $vuetify.breakpoint.xs ? 'card-rounded-bottom' : 'card-rounded-right'
      "
      :height="$vuetify.breakpoint.smAndDown ? 480 : 480"
    >
      <!-- <v-card-title
        v-if="!no_title"
        style="word-break: initial"
        v-html="$t('lp_contact_form_title')"
      >
      </v-card-title> -->

      <!-- ------1----- -->
      <v-card color="transparent" v-show="contact_step == 1" flat>
        <v-card-title
          v-bind:style="{ color: color }"
          style="word-break: initial"
          class="form-title"
          v-html="$t('lp_contact_form_your_contact_info')"
        >
        </v-card-title>
        <v-card-text
          class="contact_card_text_area"
          style="overflow: scroll; height: 470px"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :background-color="color"
                v-model.trim="contactName"
                :label="$t('lp_label_name')"
                :error-messages="error_contactName"
                dense
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="contactLastname"
                :label="$t('lp_label_lastname')"
                :error-messages="error_contactLastname"
                dense
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="email"
                :label="$t('lp_label_email')"
                :error-messages="error_email"
                dense
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="company_roles"
                :label="$t('lp_label_roles')"
                dense
                persistent-hint
                v-model="selectedRole"
                :error-messages="error_selectedRole"
                class="mb-4"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn large block color="black" @click="contact_step = 2">
            <span class="yellow--text">
              Richiedi
              <v-icon>mdi-chevron-right</v-icon>
            </span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>

      <!-- ------2----- -->
      <v-card color="transparent" v-show="contact_step == 2" flat>
        <v-card-title
          v-bind:style="{ color: color }"
          style="word-break: initial"
          class="form-title"
          v-html="$t('lp_contact_form_your_company_info')"
        >
        </v-card-title>
        <v-card-text
          class="contact_card_text_area"
          style="overflow: scroll; height: 470px"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model.trim="company_name"
                :label="$t('lp_label_company_name')"
                :error-messages="error_company_name"
                dense
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="company_plants"
                :label="$t('lp_label_company_plants_count')"
                dense
                persistent-hint
                :messages="$t('lp_label_company_plants_count_description')"
                v-model="selectedPlantCount"
                :error-messages="error_selectedPlantCount"
                class="mb-4"
              ></v-select>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-select
                :items="company_sizes"
                :label="$t('lp_label_company_size')"
                dense
                persistent-hint
                :messages="$t('label_new_plant_employees_message_1')"
                v-model="selectedCompanySize"
                :error-messages="error_selectedCompanySize"
                class="mb-4"
              >
              </v-select>
            </v-col>

            <v-col cols="6" class="mt-3">
              <v-select
                style="width: 50%"
                :items="regioni()"
                :label="$t('lp_label_region')"
                dense
                persistent-hint
                v-model="selectedRegion"
                :error-messages="error_selectedRegion"
                class="mb-4 d-inline"
              >
              </v-select>
            </v-col>

            <v-col cols="6" class="mt-3">
              <v-select
                style="width: 50%"
                :items="province"
                :label="$t('lp_label_province')"
                dense
                persistent-hint
                v-model="selectedProvince"
                :error-messages="error_selectedProvince"
                class="d-inline mb-0"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="privacy_accept"
                small
                :label="$t('lp_label_privacy_accept')"
                :error-messages="error_privacyAccept"
                append-icon="mdi-information-outline"
                @click:append="openPrivacyPolicy"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" class="text-center" style="margin-top:80px">
              <v-btn small text @click="contact_step = 1"> Indietro </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            large
            block
            color="black"
            :disabled="sendingContact"
            :loading="sendingContact"
            @click="formValidate()"
          >
            <span class="yellow--text">
              Invia richiesta
              <v-icon class="ml-3">mdi-send</v-icon>
            </span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>

      <!-- ------3----- -->
      <v-card color="transparent" v-show="contact_step == 3" flat>
        <v-card-title style="word-break: initial" class="form-title">
        </v-card-title>
        <v-card-text class="contact_card_text_area" style="min-height: 284px">
          <p v-html="$t('lp_span_message_collected_contact_1')"></p>
          <p v-html="$t('lp_span_message_collected_contact_2')"></p>
          <br />
          <p v-html="$t('lp_span_message_collected_contact_3')"></p>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { findWithAttr } from "@/modules/find_with_attr";
import { gtag } from "@/gtag";
import { urlRequestEndpoint } from "@/plugins/firebase";
import { EventBus } from "@/main";

import privacyPolicy from "@/components/landings/blocks/privacyPolicy";

import {
  geoData,
  company_plants,
  company_sizes,
  company_roles,
} from "./collectData.js";

function validateEmail(mail) {
  // console.log(mail);
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

export default {
  props: ["card_background", "elevation", "color"],
  components: {
    privacyPolicy,
  },
  data: () => ({
    geoData: geoData,
    company_plants: company_plants,
    company_sizes: company_sizes,
    company_roles: company_roles,
    sendingContact: false,
    //
    dialogCollectData: false,
    dialogCollected: false,
    //
    calling_section: null,
    //
    showTooltipCompanySize: false,
    //
    email: null,
    contactName: null,
    contactLastname: null,
    company_name: null,
    selectedRegion: null,
    selectedProvince: null,
    selectedCompanySize: null,
    selectedPlantCount: null,
    selectedRole: null,
    comunicationViaEmail: true,
    privacy_accept: false,

    // ---
    // email: "alessandro.mendolia@gmail.com",
    // contactName: "alessandro",
    // contactLastname: "mendolia",
    // company_name: "avmsquare",
    // selectedRegion: "Piemonte",
    // selectedProvince: "Cuneo",
    // selectedCompanySize: "15 - 50",
    // selectedPlantCount: "2",
    // selectedRole: "Titolare / Owner",
    // comunicationViaEmail: true,

    // ---
    error_contactName: null,
    error_contactLastname: null,
    error_email: null,
    error_company_name: null,
    error_selectedRegion: null,
    error_selectedProvince: null,
    error_selectedCompanySize: null,
    error_selectedPlantCount: null,
    error_selectedRole: null,
    error_privacyAccept: null,

    //
    back_disabled: false,
    contact_step: 1,
    no_title: false,
  }),
  methods: {
    openPrivacyPolicy() {
      EventBus.$emit("open_privacy_policy");
    },
    regioni() {
      let regioni = [];
      this.geoData.forEach((element) => {
        regioni.push(element.nome);
      });
      return regioni;
    },
    formValidate() {
      let formValid = true;
      if (validateEmail(this.email) != true) {
        this.error_email = this.$t("lp_error_email");
        formValid = false;
        this.contact_step = 1;
      }

      if (!this.contactName) {
        this.error_contactName = this.$t("lp_label_contactName_error");
        formValid = false;
        this.contact_step = 1;
      }

      if (!this.contactLastname) {
        this.error_contactLastname = this.$t("lp_label_contactLastname_error");
        formValid = false;
        this.contact_step = 1;
      }

      if (!this.selectedRole) {
        this.error_selectedRole = this.$t("lp_label_role_error");
        formValid = false;
        this.contact_step = 1;
      }

      if (formValid == false) return;

      if (!this.company_name) {
        this.error_company_name = this.$t("lp_label_company_name_error");
        formValid = false;
        this.contact_step = 2;
      }
      if (!this.selectedCompanySize) {
        this.error_selectedCompanySize = this.$t("lp_label_company_size_error");
        formValid = false;
        this.contact_step = 2;
      }

      if (!this.selectedRegion) {
        this.error_selectedRegion = this.$t("lp_label_region_error");
        formValid = false;
        this.contact_step = 2;
      }
      if (!this.selectedProvince || this.selectedProvince == "") {
        this.error_selectedProvince = this.$t("lp_label_province_error");
        formValid = false;
        this.contact_step = 2;
      }

      if (!this.selectedPlantCount) {
        this.error_selectedPlantCount = this.$t(
          "lp_label_company_plants_count_error"
        );
        this.contact_step = 2;
        formValid = false;
      }

      if (this.privacy_accept != true) {
        this.error_privacyAccept = this.$t("lp_label_privacy_accept_error");
        this.contact_step = 2;
        formValid = false;
      }

      if (formValid == false) return;

      if (formValid) this.saveData();
    },
    saveData() {
      this.sendingContact = true;
      this.back_disabled = true;
      fetch(urlRequestEndpoint + "/registerNewContact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          contact_name: this.contactName,
          contact_lastname: this.contactLastname,
          email: this.email,
          company_name: this.company_name,
          region: this.selectedRegion, //
          province: this.selectedProvince, //
          company_size: this.selectedCompanySize, //
          plant_count: this.selectedPlantCount, //
          comunication_email: this.comunicationViaEmail,
          form_origin: this.formName,
          company_role: this.selectedRole,
        }),
      })
        // .then((response) => response.json())
        .then(() => {
          //   setTimeout(() => {
          //     EventBus.$emit("contactSent");
          //   }, 5000);
          this.contact_step = 3;

          gtag("event", "contact_submit", {
            form_id: this.formName,
            contact_province: this.selectedProvince,
            contact_region: this.selectedRegion,
            role: this.selectedRole,
          });

          localStorage.setItem("contactSent", 1);
          this.dialogCollectData = false;
          this.sendingContact = false;
          this.dialogCollected = true;
          this.back_disabled = false;
        })
        .catch((error) => {
          this.back_disabled = false;
          this.sendingContact = false;
          console.log(error);
        });
    },
    cleanForm() {
      this.contactName = null;
      this.contactLastname = null;
      this.email = null;
      this.company_name = null;
      this.selectedRegion = null;
      this.selectedProvince = null;
      this.selectedCompanySize = null;
      this.selectedPlantCount = null;
      this.comunicationViaEmail = true;
      this.selectedRole = null;
      //
      this.error_contactName = null;
      this.error_contactLastname = null;
      this.error_email = null;
      this.error_company_name = null;
      this.error_selectedRegion = null;
      this.error_selectedProvince = null;
      this.error_selectedCompanySize = null;
      this.error_selectedPlantCount = null;
      this.error_selectedRole = null;
    },
  },
  mounted() {
    if (localStorage.getItem("contactSent")) {
      this.contact_step = 1;
      this.no_title = true;
    }

    // this.cleanForm();
  },
  created() {},
  computed: {
    province() {
      const index_region = findWithAttr(
        this.geoData,
        "nome",
        this.selectedRegion
      );
      if (index_region > -1) {
        return this.geoData[index_region].capoluoghi;
      } else {
        return [];
      }
    },
  },
  watch: {
    contactName() {
      this.error_contactName = null;
    },
    contactLastname() {
      this.error_contactLastname = null;
    },
    selectedRole() {
      this.error_selectedRole = null;
    },
    email() {
      this.error_email = null;
    },
    company_name() {
      this.error_company_name = null;
    },
    selectedRegion() {
      this.error_selectedRegion = null;
      this.selectedProvince = null;
    },
    selectedProvince() {
      this.error_selectedProvince = null;
    },
    selectedCompanySize() {
      this.error_selectedCompanySize = null;
    },
    selectedPlantCount() {
      this.error_selectedPlantCount = null;
    },
    privacy_accept() {
      this.error_privacyAccept = null;
    },
  },
};
</script>

<style scoped>
/* .contact_card_text_area {
  display: flex;
  flex-direction: column;
} */

.card-rounded-bottom {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.card-rounded-right {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

/* small screen */
@media only screen and (max-width: 600px) {
  .form-title {
    font-size: 1rem;
    font-weight: 700;
  }
}

/* large screen */
@media only screen and (min-width: 600px) {
  .form-title {
  }
}
</style>