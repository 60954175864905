<template>
  <div
    v-bind:style="$vuetify.breakpoint.mdAndDown ? {} : { 'max-width': '75%' }"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <v-card
          class="offer-box fill-height"
          v-bind:class="
            $vuetify.breakpoint.xs ? 'card-rounded-top' : 'card-rounded-left'
          "
        >
          <v-card-text class="offer-card-text">
            <!--  -->
            <h3>
              Promozione riservata alle aziende<br />
              <span class="area">Area<br />Monregalese</span>
              <br />
              <br />
              Dimostrazione e configurazione gratuita
              <p class="plus ma-0 mt-3 mb-1">+</p>
              <br />
              Prova SafetyFirst<br />Gratis per
              <span style="font-size: 1.5rem">60</span> giorni<br />
              <p class="plus ma-0 mt-3 mb-1">+</p>
              <br />
              <span class="discount-value"> 15%</span>
              di sconto <br />per il primo anno!
            </h3>
            <p class="no-creditcard">
              Software in Cloud<br />Nessuna installazione necessaria<br />Non è
              richiesta alcuna carta di credito
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <cardCollectContact elevation="0" card_background="white" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cardCollectContact from "@/components/landings/blocks/cardCollectContact.vue";

export default {
  props: {},
  components: {
    cardCollectContact,
  },
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.plus {
  /* padding-top:1rem;
    padding-bottom:1rem; */
  font-size: 3rem;
  font-weight: bold;
}

.discount-value {
  font-size: 2rem;
}

.offer-box {
  background-color: yellow;
  border-radius: 8px;
  /* box-shadow: 2px 2px 5px black; */
}

.area {
  font-size: 2rem;
}

.offer-card-text {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black !important;
}

.card-rounded-top {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.card-rounded-left {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.no-creditcard {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
  min-width: 100px;
}
</style>>
